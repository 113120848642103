import React, { Component } from 'react';
import './App.css';
import Pagebase from './components/elements/Pagebase';
import PasienMeninggal from './pages/PasienMeninggal';
import RawatJalan from './pages/RawatJalan';
import RawatInap from './pages/RawatInap';
import PasienRujuk from './pages/PasienRujuk';
import ListPatiens from './pages/ListPatients';
import Login from './pages/Login';

class App extends Component {
  state = {
    index: 0,
    valLogin: localStorage.getItem('login')
  }

  _handleActivePage = (val) => {
    this.setState({ index: val })
  }

  _renderPage = (content) => {
    const { valLogin } = this.state;
    if (valLogin) {
      return (
        <Pagebase activePage={this._handleActivePage}>
          <div className='containerContent'>{content}</div>
        </Pagebase>
      )
    } else {
      return <Login />;
    }
  }

  render() {
    const { index } = this.state;
    let content;
    if (index === 0) { content = <ListPatiens />; }
    else if (index === 1) { content = <RawatJalan />; }
    else if (index === 2) { content = <RawatInap />; }
    else if (index === 3) { content = <PasienRujuk /> }
    else { content = <PasienMeninggal /> }
    return this._renderPage(content);
  }
}

export default App;