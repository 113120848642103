import React, { Component } from 'react';
import moment from 'moment';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "99px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColNO: {
    width: "30px",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10
  },
  tableCellLeft: {
    marginTop: 5,
    fontSize: 10
  },
});

class ReportListPatients extends Component {
  render() {
    const { typeFilter, dataTable, tanggal, bulan, tahun } = this.props;
    let title = '';
    if (typeFilter === 'tanggal') {
      title = `PADA TANGGAL ${moment(tanggal).format('DD MMMM YYYY')?.toUpperCase()}`;
    } else if (typeFilter === 'bulan') {
      title = `PADA BULAN ${moment(bulan).locale('id').format('MMMM')?.toUpperCase()} ${moment().format('YYYY')}`;
    } else {
      title = `PADA TAHUN ${tahun}`;
    }
    return (
      <div>
        <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
          <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
          <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
          <Text>Kota Bandung, Jawa Barat 40291</Text>
          <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Text>LAPORAN PASIEN IGD</Text>
          <Text>{title}</Text>
        </div>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColNO}>
              <Text style={styles.tableCell}>NO</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>NAMA LENGKAP</Text>
            </View>
            <View style={styles.tableColNO}>
              <Text style={styles.tableCell}>NO RM</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>TANGGAL MASUK</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>ANAMNESA</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>DOKTER IGD</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>DOKTER SPESIALIS</Text>
            </View>
          </View>
          {(dataTable || []).map((val, idx) => {
            return (
              <View style={styles.tableRow} key={idx}>
                <View style={styles.tableColNO}>
                  <Text style={styles.tableCell}>  {idx + 1}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellLeft}>  {val?.fullname}</Text>
                </View>
                <View style={styles.tableColNO}>
                  <Text style={styles.tableCell}>  {val?.noRM}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>  {moment(val?.tglJamMasuk).format('DD/MM/YYYY')}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>  {(val?.anamnesa).map(val => val)?.join(' - ')}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellLeft}>  {val?.drIGD}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellLeft}>  {val?.drSpesialis}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </div>
    );
  }
}

export default ReportListPatients;