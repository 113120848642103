import React, { Component } from 'react';
import moment from 'moment';
import { Text } from '@react-pdf/renderer';

class PrintPreviewPasienMeninggal extends Component {
  render() {
    const { data } = this.props;
    const person = data && data[0];
    return (
      <div style={{ border: '1px solid black' }}>
        <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
          <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
          <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
          <Text>Kota Bandung, Jawa Barat 40291</Text>
          <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
          <Text style={{ fontSize: 14, textAlign: 'center' }}>REGISTRASI PASIEN MENINGGAL</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>No RM : {person?.noRM}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Nama Lengkap: {person?.fullname}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Jenis Kelamin : {person?.gender}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Tempat, Tanggal Lahir : {person?.tempatLahir}, {moment(person?.tglLahir).format('DD MMMM YYYY')}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Alamat : {person?.alamat}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>No Telepon : {person?.noTelp}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Nama Penanggung Jawab : {person?.namaPJ}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Keluhan : {person?.keluhan}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Anamnesa Utama : {person?.anamnesa.map(val => val)?.join(' - ')?.toString()}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Tujuan Poliklinik : {person?.poli}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Jenis Asuransi : {person?.asuransi}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Dokter IGD : {person?.drIGD}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Dokter Spesialis : {person?.drSpesialis}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Tanggal & Jam Masuk : {person?.tglJamMasuk}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Tanggal & Jam Keluar : {person?.tglJamKeluar}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Anamnesa Akhir : {person?.anamnesaAkhir.map(val => val)?.join(' - ')?.toString()}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Alasan Pasien Keluar : {person?.alasanKeluar}</Text>
        </div>
        <div style={{ borderBottom: '1px solid black', padding: 5 }}>
          <Text>Diagnosa Kematian : {person?.dxKematian.map(val => val)?.join(' - ')?.toString()}</Text>
        </div>
      </div>
    );
  }
}

export default PrintPreviewPasienMeninggal;
