import React, { Component } from 'react';
import './styles.css';
import {
  Box, Button, FormControl, Grid, IconButton,
  InputAdornment, InputLabel, Modal, OutlinedInput, TextField
} from '@mui/material';
import { Cancel, CheckCircle, Visibility, VisibilityOff } from '@mui/icons-material';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

class Login extends Component {
  state = {
    username: '',
    password: '',
    showPassword: false,
    modalSuccess: false,
    modalError: false,
  }

  _handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  _handleChangeLogin = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleLogin = () => {
    const { username, password } = this.state;
    if (username === 'pipihsilvy' && password === 'pipihsilvy') {
      this.setState({ modalSuccess: true });
    } else {
      this.setState({ modalError: true })
    }
  }

  _handleTryAgain = () => {
    this.setState({ modalError: false });
  }

  _handleGoToDashboard = () => {
    this.setState({ modalSuccess: false });
    localStorage.setItem('login', true);
    window.location.reload();
  }

  _renderModalSuccessLogin = () => {
    const { modalError, modalSuccess } = this.state;
    return (
      <Modal open={modalError || modalSuccess}>
        <Box sx={styleModal}>
          {
            modalError &&
            <div style={{ textAlign: 'center' }}>
              <Cancel style={{ color: 'red', fontSize: '100px' }} />
              <h2>Login Gagal!</h2>
              <div>Periksa kembali Username dan Password yang anda masukkan</div>
              <Button
                variant='contained'
                sx={{ marginTop: '15px' }}
                onClick={this._handleTryAgain}
              >
                Coba Lagi
              </Button>
            </div>
          }
          {
            modalSuccess &&
            <div style={{ textAlign: 'center' }}>
              <CheckCircle style={{ color: 'green', fontSize: '100px' }} />
              <h2>Login Berhasil!</h2>
              <div>Silahkan masuk ke halaman dashboard pendaftaran pasien IGD</div>
              <Button
                variant='contained'
                sx={{ marginTop: '15px' }}
                onClick={this._handleGoToDashboard}
              >
                Dashboard IGD
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  render() {
    const { showPassword } = this.state;
    return (
      <Grid container spacing={2} style={{ margin: 0, width: '100%' }}>
        {this._renderModalSuccessLogin()}
        <Grid className='container-img' item xs={6}>
          <div className='img-bg' />
        </Grid>
        <Grid className='container-card' item xs={6}>
          <div className='card'>
            <div className='container-login'>
              <div>
                <h1>Selamat Datang</h1>
                <div>Silahkan Login untuk masuk ke pendaftaran pasien IGD</div>
              </div>
              <div className='container-fields'>
                <TextField
                  className='wrapp-field'
                  name='username'
                  sx={{ width: '400px' }}
                  onChange={this._handleChangeLogin}
                  label="Username" variant="outlined"
                />
                <FormControl sx={{ width: '400px' }} variant="outlined" className='wrapp-field'>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this._handleClickShowPassword}
                          onMouseDown={this._handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    name='password'
                    onChange={this._handleChangeLogin}
                  />
                </FormControl>
              </div>
            </div>
            <Button
              sx={{ width: '400px', marginTop: '-100px' }}
              variant='contained'
              onClick={this._handleLogin}
            >
              LOGIN
            </Button>
            <span class="top"></span>
            <span class="right"></span>
            <span class="bottom"></span>
            <span class="left"></span>
          </div>
        </Grid>
      </Grid >
    );
  }
}

export default Login;