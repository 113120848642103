import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyCjVHqKaFdTz6Qr6ZPb5FTN_K048t82EZs",
    authDomain: "instalasi-gawat-darurat-8bcf2.firebaseapp.com",
    projectId: "instalasi-gawat-darurat-8bcf2",
    storageBucket: "instalasi-gawat-darurat-8bcf2.appspot.com",
    messagingSenderId: "1064393153303",
    appId: "1:1064393153303:web:186ec27795e45abc85b552"
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

export default StartFirebase;
