import { Autocomplete, Box, Button, Grid, LinearProgress, MenuItem, Modal, TextField } from '@mui/material';
import React, { Component } from 'react';
import './styles.css';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import StartFirebase from '../../../configFirebase/index';
import { ref, set, onValue } from 'firebase/database';
import moment from 'moment/moment';
import { CheckCircle } from '@mui/icons-material';

const db = StartFirebase();

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

class PasienBaru extends Component {
  state = {
    database: [],
    dataTable: [],
    dataAnamnesa: [],
    nik: '',
    fullname: '',
    gender: '',
    tempatLahir: '',
    tglLahir: '',
    alamat: '',
    noTelp: '',
    namaPJ: '',
    keluhan: '',
    anamnesa: '',
    poli: '',
    asuransi: '',
    drIGD: '',
    drSpesialis: '',
    tglJamMasuk: '',
    noRM: 0,
    modalSubmit: false,
    loading: false,
    queueNumber: 0
  }

  componentDidMount() {
    this._handleFilldataICD10();
    const dbRef = ref(db, 'pasien');
    this.setState({ database: StartFirebase() });
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({ dataTable: records })
    });
  }

  _handleFilldataICD10 = async () => {
    const { anamnesa } = this.state;
    const response10 = await fetch(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&authenticity_token=&terms=${anamnesa}`);
    const data10 = await response10?.json();
    this.setState({ dataAnamnesa: data10 });
  }

  getAllData = () => {
    let newData = [...this.state.dataTable.reduce((a, c) => (a.set(c.noRM, c)), new Map()).values()];
    return {
      nik: this.state.nik,
      fullname: this.state.fullname,
      gender: this.state.gender,
      tempatLahir: this.state.tempatLahir,
      tglLahir: this.state.tglLahir,
      alamat: this.state.alamat,
      noTelp: this.state.noTelp,
      namaPJ: this.state.namaPJ,
      keluhan: this.state.keluhan,
      anamnesa: this.state.anamnesa,
      poli: this.state.poli,
      asuransi: this.state.asuransi,
      drIGD: this.state.drIGD,
      drSpesialis: this.state.drSpesialis,
      tglJamMasuk: this.state.tglJamMasuk,
      noRM: newData.length + 1,
      queueNumber: this.state.dataTable.length + 1
    };
  }

  _handleSave = (e) => {
    e.preventDefault();
    let db = this.state.database;
    let data = this.getAllData();
    set(ref(db, 'pasien/' + data.queueNumber), {
      noRM: data.noRM?.toString(),
      fullname: data.fullname,
      nik: data.nik,
      gender: data.gender,
      tempatLahir: data.tempatLahir,
      tglLahir: data.tglLahir,
      alamat: data.alamat,
      noTelp: data.noTelp,
      namaPJ: data.namaPJ,
      keluhan: data.keluhan,
      anamnesa: data.anamnesa,
      poli: data.poli,
      asuransi: data.asuransi,
      drIGD: data.drIGD,
      drSpesialis: data.drSpesialis,
      tglJamMasuk: data.tglJamMasuk,
      queueNumber: data.queueNumber
    });

    this.setState({ modalSubmit: true, loading: true },
      () => setTimeout(() => this.setState({ loading: false }), 500))
  }

  _handleCloseModal = (e) => {
    e.preventDefault();
    this.setState({
      modalSubmit: false,
      nik: '',
      fullname: '',
      gender: '',
      tempatLahir: '',
      tglLahir: '',
      alamat: '',
      noTelp: '',
      namaPJ: '',
      keluhan: '',
      anamnesa: '',
      poli: '',
      asuransi: '',
      drIGD: '',
      drSpesialis: '',
      tglJamMasuk: ''
    }, () => window.location.reload());
  }

  _handleChangeValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleChangeTglLahir = (e) => {
    this.setState({
      tglLahir: moment(e.target.value).format('DD/MM/YYYY')
    })
  }

  _handleChangeTglJamMasuk = (e) => {
    this.setState({ tglJamMasuk: moment(e?.$d).format('DD MMMM YYYY, hh:mm a') })
  }

  _handleChangeValueAnamnesa = (e, val) => {
    this.setState({ anamnesa: val });
  }

  _changeValAnamnesa = (e) => {
    this.setState({ anamnesa: e.target.value }, () => this._handleFilldataICD10());
  }

  _renderModalSubmit = () => {
    return (
      <Modal open={this.state.modalSubmit}>
        <Box sx={styleModal}>
          {this.state.loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <CheckCircle color='success' sx={{ fontSize: 150 }} />
              <h3>Data Berhasil Tersimpan!</h3>
              <Button
                variant="contained"
                color="success"
                onClick={this._handleCloseModal}
              >
                OK
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        {this._renderModalSubmit()}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              className='wrappField'
              label="NIK"
              name='nik'
              fullWidth
              onChange={this._handleChangeValue}
              variant="filled"
            />
            <TextField
              className='wrappField'
              label="Nama Lengkap"
              name='fullname'
              fullWidth
              onChange={this._handleChangeValue}
              variant="filled"
            />
            <TextField
              className='wrappField'
              id="outlined-select-currency"
              select
              label="Jenis Kelamin"
              fullWidth
              name='gender'
              onChange={this._handleChangeValue}
              variant="filled"
            >
              {['Laki-Laki', 'Perempuan'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className='wrappField'
              label="Tempat Lahir"
              name='tempatLahir'
              fullWidth
              onChange={this._handleChangeValue}
              variant="filled"
            />
            <div className='wrappLabel'>Tanggal Lahir</div>
            <TextField
              className='wrappField'
              type='date'
              fullWidth
              name='tglLahir'
              onChange={this._handleChangeTglLahir}
              variant="filled"
            />
            <TextField
              className='wrappField'
              label="Alamat"
              fullWidth
              multiline
              name='alamat'
              onChange={this._handleChangeValue}
              rows={4.4}
              variant="filled"
              type='area'
            />
            <TextField
              className='wrappField'
              label="No Telepon"
              name='noTelp'
              fullWidth
              onChange={this._handleChangeValue}
              variant="filled"
            />
            <TextField
              className='wrappField'
              label="Nama Penanggung Jawab"
              name='namaPJ'
              fullWidth
              onChange={this._handleChangeValue}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className='wrappField'
              label="Keluhan"
              fullWidth
              multiline
              name='keluhan'
              onChange={this._handleChangeValue}
              rows={4.3}
              variant="filled"
              type='area'
            />
            <Autocomplete
              className='wrappField'
              disablePortal
              id="combo-box-demo"
              onChange={this._handleChangeValueAnamnesa}
              options={this.state.dataAnamnesa && this.state.dataAnamnesa[3]}
              fullWidth
              renderInput={(params) =>
                <TextField {...params} label="Anamnesa Utama" variant='filled' onChange={this._changeValAnamnesa} />
              }
            />
            <TextField
              className='wrappFieldTujuanPoli'
              id="outlined-select-currency"
              select
              label="Tujuan Poli"
              fullWidth
              name='poli'
              onChange={this._handleChangeValue}
              variant="filled"
            >
              {['IGD', 'Syaraf', 'Mata'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className='wrappField'
              id="outlined-select-currency"
              select
              label="Jenis Asuransi"
              fullWidth
              name='asuransi'
              onChange={this._handleChangeValue}
              variant="filled"
            >
              {['Umum', 'BPJS', 'InHealth'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className='wrappField'
              id="outlined-select-currency"
              select
              label="Dokter IGD"
              fullWidth
              name='drIGD'
              onChange={this._handleChangeValue}
              variant="filled"
            >
              {['dr. Roni', 'dr. Herdiansyah', 'dr. Fajar'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className='wrappFieldDokterSpesialis'
              id="outlined-select-currency"
              select
              label="Dokter Spesialis"
              fullWidth
              name='drSpesialis'
              onChange={this._handleChangeValue}
              variant="filled"
            >
              {['dr. Lutfhi, SpB', 'dr. Reynaldy, SpA', 'dr. Tambora, SpOG'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <LocalizationProvider className="wrappField" dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={['MobileDateTimePicker', 'MobileDateTimePicker']}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.06)'
                }}
              >
                <MobileDateTimePicker label={'Tanggal & Jam Masuk'} openTo="year" onChange={this._handleChangeTglJamMasuk} />
              </DemoContainer>
            </LocalizationProvider>
            <div className='containerBtn'>
              <Button
                variant='contained'
                onClick={this._handleSave}
                disabled={
                  this.state.nik === '' ||
                    this.state.fullname === '' ||
                    this.state.gender === '' ||
                    this.state.tempatLahir === '' ||
                    this.state.tglLahir === '' ||
                    this.state.alamat === '' ||
                    this.state.noTelp === '' ||
                    this.state.namaPJ === '' ||
                    this.state.keluhan === '' ||
                    this.state.anamnesa === '' ||
                    this.state.poli === '' ||
                    this.state.asuransi === '' ||
                    this.state.drIGD === '' ||
                    this.state.drSpesialis === '' ||
                    this.state.tglJamMasuk === '' ? true : false
                }
              >
                Simpan
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default PasienBaru;