import React, { Component } from 'react';
import './styles.css';
import {
  Box, FormControl, Button, IconButton,
  InputAdornment, Modal, OutlinedInput,
  Pagination, Tab, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  InputLabel, Select, MenuItem, Grid,
  TextField
} from '@mui/material';
import moment from 'moment';
import { Cancel, Print, Search } from '@mui/icons-material';
import StartFirebase from '../../configFirebase/index';
import { ref, onValue } from 'firebase/database';
import { Document, PDFViewer, Page, View } from '@react-pdf/renderer';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import PrintPreviewMasukRJ from '../../components/forms/PrintPreviewMasukRJ';
import PrintPreviewKeluarRJ from '../../components/forms/PrintPreviewKeluarRJ';
import PrintPreviewMasukRI from '../../components/forms/PrintPreviewMasukRI';
import PrintPreviewKeluarRI from '../../components/forms/PrintPreviewKeluarRI';
import PrintPreviewPasienRujuk from '../../components/forms/PrintPreviewPasienRujuk';
import PrintPreviewPasienMeninggal from '../../components/forms/PrintPreviewPasienMeninggal';
import ReportListPatients from './reportListPatients';

const db = StartFirebase();
const pageSize = 5;

const page = {
  backgroundColor: "white",
  color: "black",
  fontSize: 12
}

const viewer = {
  width: '100%',
  height: '450px',
}

const viewerListPatients = {
  width: '99%',
  height: '550px',
}

const section = {
  margin: 10,
  padding: 10,
}

const styleModalForm = {
  width: '100%',
  height: 550,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  marginTop: '30px',
  typography: 'body1'
};

const styleModalListPatients = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '555px',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  marginTop: '30px',
  typography: 'body1'
};

const styleModalFilter = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

class ListPatiens extends Component {
  state = {
    dataTable: [],
    database: [],
    preliminaryData: [],
    search: '',
    idData: '',
    showModal: false,
    content: 1,
    pagination: {
      count: 0,
      from: 0,
      to: pageSize
    },
    showFilter: false,
    typeFilter: '',
    tanggal: '',
    bulan: '',
    tahun: '',
    filtered: false,
    printList: false
  }

  componentDidMount() {
    const dbRef = ref(db, 'pasien');
    this.setState({ database: StartFirebase() });
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({
        dataTable: records,
        preliminaryData: records,
        pagination: {
          ...this.state.pagination,
          count: records.length
        }
      });
    });
  }

  _handleChangeSearch = (e) => {
    e.preventDefault();
    this.setState({ search: e.target.value }, () => {
      this._handleSearchPatient();
    });
  }

  _handleSearchPatient = () => {
    const { search } = this.state;
    let searchPatient = this.state.preliminaryData.filter((val) => {
      return val?.fullname.toLowerCase().includes(search.toLowerCase());
    }
    );
    this.setState({ dataTable: searchPatient });
  }

  _handlePreview = (id) => (e) => {
    e.preventDefault();
    this.setState({ idData: id, showModal: true })
  }

  _handleClosePreview = () => {
    this.setState({ showModal: false, idData: '', content: 1 });
  }

  _handleChangeTab = (e, val) => {
    this.setState({ content: val })
  }

  _handleChangePagination = (e, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    this.setState({
      pagination: {
        ...this.state.pagination,
        from, to
      }
    });
  }

  _handleFilter = () => {
    this.setState({ showFilter: true });
  }

  _handleCloseModalFilter = () => {
    this.setState({
      showFilter: false, tanggal: '',
      bulan: '', tahun: '', typeFilter: ''
    });
  }

  _handleChangeTypeFilter = (e) => {
    this.setState({ typeFilter: e.target.value })
    if (e.target.valeu === 'tanggal') {
      this.setState({ bulan: '', tahun: '' })
    } else if (e.target.value === 'bulan') {
      this.setState({ tanggal: '', tahun: '' })
    } else {
      this.setState({ bulan: '', tanggal: '' })
    }
  }

  _handleChangeFilter = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleSaveFilter = () => {
    const { dataTable, typeFilter, tanggal, bulan, tahun } = this.state;
    if (typeFilter === 'tanggal') {
      let newData = (dataTable || []).filter(val => moment(val?.tglJamMasuk).format('YYYY-MM-DD') === tanggal);
      this.setState({
        dataTable: newData, preliminaryData: newData,
        filtered: true, showFilter: false
      });
    } else if (typeFilter === 'bulan') {
      let newData = (dataTable || []).filter(val => moment(val?.tglJamMasuk).format('MM-YYYY') === `${bulan}-${moment().format('YYYY')}`);
      this.setState({
        dataTable: newData, preliminaryData: newData,
        filtered: true, showFilter: false
      });
    } else {
      let newData = (dataTable || []).filter(val => moment(val?.tglJamMasuk).format('YYYY') === tahun);
      this.setState({
        dataTable: newData, preliminaryData: newData,
        filtered: true, showFilter: false
      });
    }
  }

  _handleResetFilter = () => {
    const dbRef = ref(db, 'pasien');
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({
        dataTable: records, preliminaryData: records,
        filtered: false, tanggal: '', bulan: '',
        tahun: '', typeFilter: ''
      });
    });
  }

  _handlePrintList = () => {
    this.setState({ printList: !this.state.printList })
  }

  _renderSearch = () => {
    const { filtered } = this.state;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl>
          <OutlinedInput
            endAdornment={
              <InputAdornment>
                <Search />
              </InputAdornment>
            }
            onChange={this._handleChangeSearch}
            placeholder="Cari pasien..."
            type="text"
            value={this.state.search}
            variant="standart"
            size='small'
          />
        </FormControl>
        <Button
          style={{ marginLeft: 20 }}
          variant='outlined'
          onClick={this._handleFilter}
          disabled={filtered}
        >
          Filter
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant='contained'
          color='error'
          disabled={!filtered}
          onClick={this._handleResetFilter}
        >
          Reset Filter
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          variant='contained'
          color='warning'
          disabled={!filtered}
          onClick={this._handlePrintList}
        >
          <Print fontSize='small' style={{ marginRight: 5 }} /> Print List
        </Button>
      </div>
    );
  }

  _renderModalListPatients = () => {
    const { printList, typeFilter, dataTable, tanggal, bulan, tahun } = this.state;
    return (
      <Modal open={printList}>
        <Box sx={styleModalListPatients}>
          <IconButton
            style={{
              position: 'absolute',
              top: '-31px',
              right: '-23px',
              zIndex: 2,
              color: 'white'
            }}
            onClick={this._handlePrintList}
          >
            <Cancel fontSize='large' />
          </IconButton>
          <div style={{ marginBottom: '50px' }}>
            <PDFViewer style={viewerListPatients}>
              <Document>
                <Page size="A4" style={page}>
                  <View style={section}>
                    <ReportListPatients
                      typeFilter={typeFilter}
                      dataTable={dataTable}
                      tanggal={tanggal}
                      bulan={bulan}
                      tahun={tahun}
                    />
                  </View>
                </Page>
              </Document>
            </PDFViewer>
          </div>
        </Box>
      </Modal>
    );
  }

  _renderModalForm = () => {
    const { content, dataTable, showModal, idData } = this.state;
    let dataPasien = (dataTable || []).filter((val, idx) => idx === idData);
    return (
      <Modal open={showModal}>
        <Box sx={styleModalForm}>
          <IconButton
            style={{
              position: 'absolute',
              top: 30,
              right: 10,
              zIndex: 2
            }}
            onClick={this._handleClosePreview}
          >
            <Cancel fontSize='large' />
          </IconButton>
          <div style={{
            position: 'absolute !important',
            left: '0px !important',
            top: '10px !important'
          }}>
            <TabContext value={content}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={this._handleChangeTab}>
                  <Tab label="Dokumen Masuk IGD" value="1" />
                  {
                    dataPasien && dataPasien[0] && dataPasien[0]?.selesai !== undefined &&
                    <Tab label="Dokumen Keluar IGD" value="2" />
                  }
                  {
                    (dataPasien && dataPasien[0] && dataPasien[0]?.alasanKeluar === 'Rawat Inap') &&
                    (dataPasien && dataPasien[0] && dataPasien[0]?.ruangRawat !== undefined) &&
                    <Tab label="Dokumen Masuk Watnap" value="3" />
                  }
                  {
                    (dataPasien && dataPasien[0] && dataPasien[0]?.alasanKeluar === 'Rawat Inap') &&
                    (dataPasien && dataPasien[0] && dataPasien[0]?.selesaiRawatInap !== undefined) &&
                    <Tab label="Dokumen Keluar Watnap" value="4" />
                  }
                  {
                    (dataPasien && dataPasien[0] && dataPasien[0]?.alasanKeluar === 'Rujuk') &&
                    (dataPasien && dataPasien[0] && dataPasien[0]?.namaRS !== undefined) &&
                    <Tab label="Dokumen Pasien Rujuk" value="5" />
                  }
                  {
                    (dataPasien && dataPasien[0] && dataPasien[0]?.alasanKeluar === 'Meninggal') &&
                    (dataPasien && dataPasien[0] && dataPasien[0]?.dxKematian !== undefined) &&
                    <Tab label="Dokumen Pasien Meninggal" value="6" />
                  }
                </TabList>
              </Box>
              <TabPanel value="1">
                <PDFViewer style={viewer}>
                  <Document>
                    <Page size="A4" style={page}>
                      <View style={section}>
                        <PrintPreviewMasukRJ data={dataPasien} />
                      </View>
                    </Page>
                  </Document>
                </PDFViewer>
              </TabPanel>
              <TabPanel value="2">
                <PDFViewer style={viewer}>
                  <Document>
                    <Page size="A4" style={page}>
                      <View style={section}>
                        <PrintPreviewKeluarRJ data={dataPasien} />
                      </View>
                    </Page>
                  </Document>
                </PDFViewer>
              </TabPanel>
              <TabPanel value="3">
                <PDFViewer style={viewer}>
                  <Document>
                    <Page size="A4" style={page}>
                      <View style={section}>
                        <PrintPreviewMasukRI data={dataPasien} />
                      </View>
                    </Page>
                  </Document>
                </PDFViewer>
              </TabPanel>
              <TabPanel value="4">
                <PDFViewer style={viewer}>
                  <Document>
                    <Page size="A4" style={page}>
                      <View style={section}>
                        <PrintPreviewKeluarRI data={dataPasien} />
                      </View>
                    </Page>
                  </Document>
                </PDFViewer>
              </TabPanel>
              <TabPanel value="5">
                <PDFViewer style={viewer}>
                  <Document>
                    <Page size="A4" style={page}>
                      <View style={section}>
                        <PrintPreviewPasienRujuk data={dataPasien} />
                      </View>
                    </Page>
                  </Document>
                </PDFViewer>
              </TabPanel>
              <TabPanel value="6">
                <PDFViewer style={viewer}>
                  <Document>
                    <Page size="A4" style={page}>
                      <View style={section}>
                        <PrintPreviewPasienMeninggal data={dataPasien} />
                      </View>
                    </Page>
                  </Document>
                </PDFViewer>
              </TabPanel>
            </TabContext>
          </div>
        </Box>
      </Modal>
    );
  }

  _renderFilter = () => {
    const { showFilter, typeFilter, tanggal, bulan, tahun } = this.state;

    let content;
    if (typeFilter === 'tanggal') {
      content =
        <TextField
          type='date'
          name='tanggal'
          value={tanggal}
          onChange={this._handleChangeFilter}
        />
    } else if (typeFilter === 'bulan') {
      content = (
        <FormControl fullWidth>
          <InputLabel>Pilih Bulan</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Pilih Bulan"
            value={bulan}
            variant='outlined'
            name='bulan'
            endAdornment={moment().format('YYYY')}
            IconComponent={() => null}
            onChange={this._handleChangeFilter}
          >
            <MenuItem value='01'>Januari</MenuItem>
            <MenuItem value='02'>Februari</MenuItem>
            <MenuItem value='03'>Maret</MenuItem>
            <MenuItem value='04'>April</MenuItem>
            <MenuItem value='05'>Mei</MenuItem>
            <MenuItem value='06'>Juni</MenuItem>
            <MenuItem value='07'>Juli</MenuItem>
            <MenuItem value='08'>Agustus</MenuItem>
            <MenuItem value='09'>September</MenuItem>
            <MenuItem value='10'>Oktober</MenuItem>
            <MenuItem value='11'>November</MenuItem>
            <MenuItem value='12'>Desember</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (typeFilter === 'tahun') {
      content = (
        <FormControl fullWidth>
          <InputLabel>Pilih Tahun</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Pilih Tahun"
            value={tahun}
            variant='outlined'
            name='tahun'
            onChange={this._handleChangeFilter}
          >
            <MenuItem value='2023'>2023</MenuItem>
            <MenuItem value='2022'>2022</MenuItem>
            <MenuItem value='2021'>2021</MenuItem>
            <MenuItem value='2020'>2020</MenuItem>
            <MenuItem value='2019'>2019</MenuItem>
            <MenuItem value='2018'>2018</MenuItem>
            <MenuItem value='2017'>2017</MenuItem>
            <MenuItem value='2016'>2016</MenuItem>
            <MenuItem value='2015'>2015</MenuItem>
            <MenuItem value='2014'>2014</MenuItem>
          </Select>
        </FormControl>
      );
    } else { content = ''; }

    return (
      <Modal open={showFilter} onClose={this._handleCloseModalFilter}>
        <Box sx={styleModalFilter}>
          <Grid sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }} container spacing={1}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Berdasarkan</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeFilter}
                  label="Berdasarkan"
                  variant='outlined'
                  onChange={this._handleChangeTypeFilter}
                >
                  <MenuItem value='tanggal'>Tanggal</MenuItem>
                  <MenuItem value='bulan'>Bulan</MenuItem>
                  <MenuItem value='tahun'>Tahun</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {content}
            </Grid>
          </Grid>
          <Button
            disabled={
              tanggal !== '' ||
                bulan !== '' ||
                tahun !== '' ? false : true
            }
            variant='contained'
            onClick={this._handleSaveFilter}
          >
            Simpan
          </Button>
        </Box>
      </Modal>
    );
  }

  render() {
    const { dataTable, pagination } = this.state;
    const newData = dataTable.slice(pagination?.from, pagination?.to);
    return (
      <React.Fragment>
        {this._renderSearch()}
        {this._renderModalForm()}
        {this._renderFilter()}
        {this._renderModalListPatients()}
        <TableContainer style={{ height: '500px', overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><b>NO RM</b></TableCell>
                <TableCell><b>NAMA</b></TableCell>
                <TableCell><b>ASURANSI</b></TableCell>
                <TableCell><b>ANAMNESA</b></TableCell>
                <TableCell><b>DOKTER IGD</b></TableCell>
                <TableCell><b>DOKTER SPESIALIS</b></TableCell>
                <TableCell align='center'><b>STATUS</b></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataTable.length === 0 ?
                <TableRow>
                  <TableCell colSpan={8}>Tidak Ada Pasien</TableCell>
                </TableRow> :
                (newData || []).map((val, idx) => {

                  let styleKeluar = '';
                  if (val?.alasanKeluar === 'Rawat Jalan') { styleKeluar = 'rawatJalan'; }
                  else if (val?.alasanKeluar === 'Rawat Inap') { styleKeluar = 'rawatInap'; }
                  else if (val?.alasanKeluar === 'Rujuk') { styleKeluar = 'rujuk'; }
                  else if (val?.alasanKeluar === 'Meninggal') { styleKeluar = 'meninggal'; }
                  else { styleKeluar = ''; }

                  return (
                    <TableRow key={idx}>
                      <TableCell>{val?.noRM}</TableCell>
                      <TableCell>{val?.fullname}</TableCell>
                      <TableCell>{val?.asuransi}</TableCell>
                      <TableCell>{val?.anamnesa}</TableCell>
                      <TableCell>{val?.drIGD}</TableCell>
                      <TableCell>{val?.drSpesialis}</TableCell>
                      <TableCell>
                        <div className={val?.selesai === undefined ? 'dalamProses' : 'selesai'}>
                          {val?.selesai === undefined ? 'Dalam Proses' : 'Selesai'}
                        </div>
                        <div className={styleKeluar}>
                          {val?.alasanKeluar}
                        </div>
                        {
                          (val?.alasanKeluar === 'Rawat Inap' && val?.selesaiRawatInap !== undefined) &&
                          <div style={{
                            padding: '5px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            width: '100px',
                            backgroundColor: 'rgb(171, 171, 171)',
                          }}>
                            Pasien Pulang
                          </div>
                        }
                        {
                          (val?.alasanKeluar === 'Rujuk' && val?.namaRS !== undefined) &&
                          <div style={{
                            padding: '5px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            width: '100px',
                            backgroundColor: 'rgb(171, 171, 171)',
                          }}>
                            Pasien Pulang
                          </div>
                        }
                        {
                          (val?.alasanKeluar === 'Meninggal' && val?.dxKematian !== undefined) &&
                          <div style={{
                            padding: '5px',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            width: '100px',
                            backgroundColor: 'rgb(171, 171, 171)',
                          }}>
                            Pasien Pulang
                          </div>
                        }
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={this._handlePreview(idx)}><Print /></IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          justifyContent={"flex-end"}
          alignItems={"center"}
          display={"flex"}
          sx={{
            position: 'fixed',
            bottom: '20px',
            right: '10px',
          }}
        >
          <Pagination
            color='primary'
            count={Math.ceil(pagination?.count / pageSize)}
            onChange={this._handleChangePagination}
          />
        </Box>
      </React.Fragment >
    );
  }
}

export default ListPatiens;
