import React, { Component } from 'react';
import './styles.css';
import PasienBaru from './PasienBaru';
import PasienLama from './PasienLama';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

class PasienMasukRJ extends Component {
  state = {
    jenisPasien: ''
  }

  _handleChange = (e) => {
    this.setState({ jenisPasien: e.target.value });
  }

  render() {
    const { jenisPasien } = this.state;

    let content = '';
    if (jenisPasien === 'baru') {
      content = <PasienBaru />;
    } else if (jenisPasien === 'lama') {
      content = <PasienLama />;
    } else {
      content = '';
    }

    return (
      <div>
        <FormControl className='wrappJenisPasien'>
          <FormLabel className='label'>Jenis Pasien :</FormLabel>
          <RadioGroup row onChange={this._handleChange} value={jenisPasien}>
            <FormControlLabel value="baru" control={<Radio />} label="Pasien Baru" />
            <FormControlLabel value="lama" control={<Radio />} label="Pasien Lama" />
          </RadioGroup>
        </FormControl>
        {content}
      </div>
    );
  }
}

export default PasienMasukRJ;