import React, { Component } from 'react';
import './styles.css';
import { AddCircleOutline, AssignmentSharp, BedroomChild, DirectionsWalkOutlined, FormatListBulleted, Logout } from '@mui/icons-material';

let list = [
  {
    icon: <FormatListBulleted style={{ marginRight: '5px' }} />,
    title: 'List Pasien'
  },
  {
    icon: <DirectionsWalkOutlined style={{ marginRight: '5px' }} />,
    title: 'IGD'
  },
  {
    icon: <BedroomChild style={{ marginRight: '5px' }} />,
    title: 'Rawat Inap'
  },
  {
    icon: <AssignmentSharp style={{ marginRight: '5px' }} />,
    title: 'Pasien Rujuk'
  },
  {
    icon: <AddCircleOutline style={{ marginRight: '5px' }} />,
    title: 'Pasien Meninggal'
  }
]

class Sidebar extends Component {
  state = {
    menu: 0
  }

  _handleSubMenuActive = (idx) => (e) => {
    const { menuActive } = this.props;
    e.preventDefault();
    if (idx === this.state.menu) {
      this.setState({ menu: idx });
    } else {
      this.setState({ menu: idx });
    }
    menuActive(idx);
  };

  _handleLogout = () => {
    localStorage.removeItem('login');
    window.location.reload();
  }

  render() {
    const { menu } = this.state;
    return (
      <div className='containerSidebar'>
        <h1 style={{ textAlign: 'center', borderBottom: '1px dashed white', paddingBottom: 15 }}>INSTALASI GAWAT DARURAT</h1>
        {list.map((val, idx) => {
          return (
            <div
              className={menu === idx ? 'title-active' : 'title'}
              onClick={this._handleSubMenuActive(idx)}
            >
              {val.icon} {val.title}
            </div>
          );
        })}
        <div className='title-logout' onClick={this._handleLogout}>
          <Logout style={{ marginRight: '5px' }} /> Logout
        </div>
      </div>
    );
  }
}

export default Sidebar;