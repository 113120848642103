import { Autocomplete, Box, Button, Grid, LinearProgress, MenuItem, Modal, TextField } from '@mui/material';
import React, { Component } from 'react';
import { ref, set, onValue } from 'firebase/database';
import StartFirebase from '../../../configFirebase';
import moment from 'moment';
import './styles.css';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CheckCircle } from '@mui/icons-material';

const db = StartFirebase();

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

class PasienKeluarRJ extends Component {
  state = {
    database: [],
    dataTable: [],
    dataAnamnesa: [],
    dataPasien: {},
    showFields: false,
    tglJamKeluar: '',
    anamnesaAkhir: '',
    alasanKeluar: '',
    selesai: false,
    modalSubmit: false,
    loading: false
  }

  componentDidMount() {
    this._handleFilldataICD10();
    const dbRef = ref(db, 'pasien');
    this.setState({ database: StartFirebase() })
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({ dataTable: records })
    });
  }

  _handleFilldataICD10 = async () => {
    const { anamnesaAkhir } = this.state;
    const response10 = await fetch(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&authenticity_token=&terms=${anamnesaAkhir}`);
    const data10 = await response10?.json();
    this.setState({ dataAnamnesa: data10 });
  }

  getAllData = () => {
    return {
      nik: this.state.dataPasien.nik,
      fullname: this.state.dataPasien.fullname,
      gender: this.state.dataPasien.gender,
      tempatLahir: this.state.dataPasien.tempatLahir,
      tglLahir: this.state.dataPasien.tglLahir,
      alamat: this.state.dataPasien.alamat,
      noTelp: this.state.dataPasien.noTelp,
      namaPJ: this.state.dataPasien.namaPJ,
      keluhan: this.state.dataPasien.keluhan,
      anamnesa: this.state.dataPasien.anamnesa,
      poli: this.state.dataPasien.poli,
      asuransi: this.state.dataPasien.asuransi,
      drIGD: this.state.dataPasien.drIGD,
      drSpesialis: this.state.dataPasien.drSpesialis,
      tglJamMasuk: this.state.dataPasien.tglJamMasuk,
      noRM: this.state.dataPasien.noRM,
      queueNumber: this.state.dataPasien.queueNumber,
      tglJamKeluar: this.state.tglJamKeluar,
      anamnesaAkhir: this.state.anamnesaAkhir,
      alasanKeluar: this.state.alasanKeluar,
      selesai: true
    };
  }

  _handleSave = (e) => {
    e.preventDefault();
    let db = this.state.database;
    let data = this.getAllData();

    set(ref(db, 'pasien/' + data.queueNumber), {
      noRM: data.noRM,
      fullname: data.fullname,
      nik: data.nik,
      gender: data.gender,
      tempatLahir: data.tempatLahir,
      tglLahir: data.tglLahir,
      alamat: data.alamat,
      noTelp: data.noTelp,
      namaPJ: data.namaPJ,
      keluhan: data.keluhan,
      anamnesa: data.anamnesa,
      poli: data.poli,
      asuransi: data.asuransi,
      drIGD: data.drIGD,
      drSpesialis: data.drSpesialis,
      tglJamMasuk: data.tglJamMasuk,
      queueNumber: data.queueNumber,
      tglJamKeluar: data.tglJamKeluar,
      anamnesaAkhir: data.anamnesaAkhir,
      alasanKeluar: data.alasanKeluar,
      selesai: data.selesai
    });

    this.setState({ modalSubmit: true, loading: true },
      () => setTimeout(() => this.setState({ loading: false }), 500));
  }

  _handleChangeNoRM = (e, val) => {
    this.setState({ dataPasien: val, showFields: !this.state.showFields });
  }

  _handleChangeValRM = (e) => {
    this.setState({ dataPasien: e.target.value });
  }

  _handleChangeValue = (e) => {
    this.setState({ alasanKeluar: e.target.value });
  }

  _handleChangeValueAnamnesaAkhir = (e, val) => {
    this.setState({ anamnesaAkhir: val });
  }

  _changeValAnamnesaAkhir = (e) => {
    this.setState({ anamnesaAkhir: e.target.value }, () => this._handleFilldataICD10());
  }

  _handleChangeTglJamKeluar = (e) => {
    this.setState({ tglJamKeluar: moment(e?.$d).format('DD MMMM YYYY, hh:mm a') })
  }

  _handleCloseModal = (e) => {
    e.preventDefault();
    this.setState({
      modalSubmit: false,
      dataPasien: {},
      tglJamKeluar: '',
      anamnesaAkhir: '',
      alasanKeluar: '',
      selesai: false,
    }, () => window.location.reload());
  }

  _renderModalSubmit = () => {
    return (
      <Modal open={this.state.modalSubmit}>
        <Box sx={styleModal}>
          {this.state.loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <CheckCircle color='success' sx={{ fontSize: 150 }} />
              <h3>Data Berhasil Tersimpan!</h3>
              <Button
                variant="contained"
                color="success"
                onClick={this._handleCloseModal}
              >
                OK
              </Button>
            </div>
          }
        </Box>
      </Modal>
    );
  }

  render() {
    const { dataPasien, showFields, dataTable } = this.state;
    let newData = dataTable.filter(val => val?.selesai === undefined);
    let bornDate = moment(dataPasien?.tglLahir, 'DD/MM/YYYY');
    return (
      <div>
        {this._renderModalSubmit()}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              className='wrappField'
              disablePortal
              id="combo-box-demo"
              onChange={this._handleChangeNoRM}
              options={newData}
              getOptionLabel={(option) => option && option.noRM}
              renderInput={(params) =>
                <TextField {...params} label="No Rekam Medis" onChange={this._handleChangeValRM} />
              }
            />
            {showFields &&
              <>
                <TextField
                  className='wrappField'
                  label="NIK"
                  name='nik'
                  fullWidth
                  disabled
                  variant="filled"
                  value={dataPasien?.nik}
                />
                <TextField
                  className='wrappField'
                  label="Nama Lengkap"
                  name='fullname'
                  fullWidth
                  disabled
                  variant="filled"
                  value={dataPasien?.fullname}
                />
                <TextField
                  className='wrappField'
                  id="outlined-select-currency"
                  select
                  label="Jenis Kelamin"
                  fullWidth
                  name='gender'
                  disabled
                  variant="filled"
                  value={dataPasien?.gender}
                >
                  {['Laki-Laki', 'Perempuan'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className='wrappField'
                  label="Tempat Lahir"
                  name='tempatLahir'
                  fullWidth
                  disabled
                  variant="filled"
                  value={dataPasien?.tempatLahir}
                />
                <div className='wrappLabel'>Tanggal Lahir</div>
                <TextField
                  className='wrappField'
                  type='date'
                  fullWidth
                  name='tglLahir'
                  disabled
                  variant="filled"
                  value={bornDate.format('YYYY-MM-DD')}
                />
                <TextField
                  className='wrappField'
                  label="Alamat"
                  fullWidth
                  multiline
                  name='alamat'
                  disabled
                  rows={4.4}
                  variant="filled"
                  type='area'
                  value={dataPasien?.alamat}
                />
                <TextField
                  className='wrappField'
                  label="No Telepon"
                  name='noTelp'
                  fullWidth
                  disabled
                  variant="filled"
                  value={dataPasien?.noTelp}
                />
              </>}
          </Grid>
          {showFields &&
            <Grid item xs={4}>
              <TextField
                className='wrappField'
                label="Nama Penanggung Jawab"
                name='namaPJ'
                fullWidth
                disabled
                variant="filled"
                value={dataPasien?.namaPJ}
              />
              <TextField
                className='wrappField'
                label="Keluhan"
                fullWidth
                multiline
                name='keluhan'
                disabled
                rows={4.3}
                variant="filled"
                type='area'
                value={dataPasien?.keluhan}
              />
              <TextField
                className='wrappField'
                disabled
                label="Anamnesa Utama"
                variant='filled'
                fullWidth
                value={`${dataPasien?.anamnesa && dataPasien?.anamnesa[0]} - ${dataPasien?.anamnesa && dataPasien?.anamnesa[1]}`}
              />
              <TextField
                className='jenisAsuransi'
                id="outlined-select-currency"
                select
                label="Tujuan Poli"
                fullWidth
                name='poli'
                disabled
                variant="filled"
                value={dataPasien?.poli}
              >
                {['IGD', 'Syaraf', 'Mata'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className='wrappField'
                id="outlined-select-currency"
                select
                label="Jenis Asuransi"
                fullWidth
                name='asuransi'
                disabled
                variant="filled"
                value={dataPasien?.asuransi}
              >
                {['Umum', 'BPJS', 'InHealth'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className='wrappField'
                id="outlined-select-currency"
                select
                label="Dokter IGD"
                fullWidth
                name='drIGD'
                disabled
                variant="filled"
                value={dataPasien?.drIGD}
              >
                {['Lutfhi', 'Reynaldy', 'Tambora'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className='wrappField'
                id="outlined-select-currency"
                select
                label="Dokter Spesialis"
                fullWidth
                name='drSpesialis'
                disabled
                variant="filled"
                value={dataPasien?.drSpesialis}
              >
                {['Reynaldy', 'Tambora', 'Lutfhi'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className='wrappField'
                disabled
                label="Tanggal & Jam Masuk"
                variant='filled'
                fullWidth
                value={dataPasien?.tglJamMasuk}
              />
            </Grid>
          }
          {showFields &&
            <Grid item xs={4}>
              <LocalizationProvider className="wrappField" dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={['MobileDateTimePicker', 'MobileDateTimePicker']}
                  sx={{
                    marginTop: -1,
                    marginBottom: '20px',
                    backgroundColor: 'rgba(0, 0, 0, 0.06)'
                  }}
                >
                  <MobileDateTimePicker
                    label={'Tanggal & Jam Keluar'}
                    openTo="year"
                    onChange={this._handleChangeTglJamKeluar}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Autocomplete
                className='wrappField'
                disablePortal
                id="combo-box-demo"
                onChange={this._handleChangeValueAnamnesaAkhir}
                options={this.state.dataAnamnesa && this.state.dataAnamnesa[3]}
                fullWidth
                renderInput={(params) =>
                  <TextField {...params} label="Anamnesa Akhir" variant='filled' onChange={this._changeValAnamnesaAkhir} />
                }
              />
              <TextField
                className='wrappFieldDokterSpesialis'
                id="outlined-select-currency"
                select
                label="Alasan Pasien Keluar"
                fullWidth
                name='alasanKeluar'
                onChange={this._handleChangeValue}
                variant="filled"
              >
                {['Rawat Jalan', 'Rawat Inap', 'Rujuk', 'Meninggal'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <div className='containerBtn'>
                <Button
                  variant='contained'
                  onClick={this._handleSave}
                  disabled={
                    this.state.tglJamKeluar === '' ||
                      this.state.anamnesaAkhir === '' ||
                      this.state.alasanKeluar === '' ? true : false
                  }
                >
                  Simpan
                </Button>
              </div>
            </Grid>
          }
        </Grid>
      </div>
    );
  }
}

export default PasienKeluarRJ;