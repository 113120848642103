import React, { Component } from 'react';
import Sidebar from '../SIdebar';

class Pagebase extends Component {

  _handleActiveMenu = (val) => {
    const { activePage } = this.props;
    activePage(val);
  }

  render() {
    const { children } = this.props;
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative'
      }}>
        <Sidebar menuActive={this._handleActiveMenu} />
        <div style={{
          width: '100%',
          height: '100vh',
          overflowY: 'auto'
        }}>
          {children}
        </div>
      </div>
    );
  }
}

export default Pagebase;