import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { Component } from 'react';
import PasienMasukRJ from '../../components/forms/PasienMasukRJ';
import PasienKeluarRJ from '../../components/forms/PasienKeluarRJ';

class RawatJalan extends Component {
  state = {
    content: 1,
  }

  _handleChange = (e, val) => {
    this.setState({ content: val })
  }

  render() {
    const { content } = this.state;
    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={content}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={this._handleChange}>
              <Tab label="Registrasi Pasien Masuk" value="1" />
              <Tab label="Registrasi Pasien Keluar" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1"><PasienMasukRJ /></TabPanel>
          <TabPanel value="2"><PasienKeluarRJ /></TabPanel>
        </TabContext>
      </Box>
    );
  }
}

export default RawatJalan;